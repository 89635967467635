export async function deleteMeetingMinute(id) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append(
    "Authorization",
    `Basic ${process.env.REACT_APP_HISTORIC_KEY}`
  );

  const response = await fetch(
    `https://tadeo-services.apps.kloud.rnp.br/historic/meeting_minute/${id}`,
    {
      method: "DELETE",
      headers: headers,
    }
  );

  if (response.status === 301) {
    return { status: 200, message: "strangely succeeded" };
  }

  if (!response.ok) {
    throw new Error("Failed to update meeting minute");
  }

  return await response.json();
}

export default deleteMeetingMinute;
