/**
 * Converte um JSON com mensagens no formato fornecido em um array de mensagens no formato esperado.
 *
 * @param {Object} jsonData - O JSON contendo as mensagens.
 * @returns {Array<Object>} - Array de mensagens no formato [{ id, text, sender }].
 */
export function parseMessages(jsonData) {
  if (!jsonData || !Array.isArray(jsonData.messages)) {
    throw new Error("Formato de JSON inválido ou mensagens ausentes.");
  }

  const messages = jsonData.messages.map((message) => ({
    id: message.id,
    text: message.speech,
    sender: message.speaker,
    startTimestamp: message["start timestamp"],
    endTimestamp: message["end timestamp"],
    startTimestamp: message["start timestamp"].split(".")[0],
    endTimestamp: message["end timestamp"].split(".")[0],
  }));

  return messages;
}
