import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { GetFileTutorial } from "./GetFileTutorial/GetFileTutorial";
import FileUpload from "../../../FileUpload/FileUpload";
import { MinuteHistory } from "./MinuteHistory/MinuteHistory";
import { useMeetingMinute } from "../../../../contexts/MeetingMinuteContext";
import getUserInfo from "../../../../server/user-auth/UserInfo";
import { Alert } from "../../../Alert/Alert";

export function UploadTranscriptionFile({ onUploadSuccess, onFileSelect }) {
  const [showTutorial, setShowTutorial] = useState(false);
  const [externalFiles, setExternalFiles] = useState([]);
  const { processVTTFile, meetingMinuteHistory, isFetchingMinuteHistory } =
    useMeetingMinute();
  const userInfo = getUserInfo();

  const userId = userInfo.uid;
  const userName = userInfo.fullName;
  const userEmail = userInfo.email;

  const handleFileSelect = (files) => {
    const vttFile = files.find((file) =>
      file.name.toLowerCase().endsWith(".vtt")
    );
    if (vttFile) {
      onFileSelect(true);
      processVTTFile(vttFile, onUploadSuccess, userEmail, userId, userName);
    }
  };

  useEffect(() => {
    if (meetingMinuteHistory.length === 0 && !isFetchingMinuteHistory) {
      setShowTutorial(true);
    }
  }, [meetingMinuteHistory]);

  return (
    <motion.div
      className={`flex mt-[65px]`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      layout
    >
      <motion.div
        className="min-w-full max-w-[800px] text-sm leading-6 text-slate-600 dark:text-slate-200"
        layout
      >
        <h2 className="text-base font-semibold leading-7 text-slate-900 dark:text-slate-200">
          Módulo de reuniões
        </h2>
        <p className="mt-1 mb-5 select-none">
          Por meio deste módulo, é possível elaborar e gerenciar atas de reunião
          detalhadas, garantindo o registro preciso das decisões, discussões e
          ações acordadas durante os encontros realizados pelo MS Teams da
          organização.
        </p>

        <p className="mt-1 ">
          Para começar, anexe o arquivo *.vtt da reunião em questão.{" "}
          <button
            className="font-bold text-indigo-600 cursor-pointer select-none dark:text-indigo-400 hover:dark:text-indigo-200 hover:text-indigo-500 smooth-all"
            onClick={() => setShowTutorial(!showTutorial)}
          >
            {!showTutorial
              ? "Clique aqui e saiba como obter o arquivo VTT."
              : "Fechar tutorial."}
          </button>
        </p>
        <FileUpload
          label=""
          validTypes={["vtt"]}
          onFileSelect={handleFileSelect}
          externalFiles={externalFiles}
        />
        <AnimatePresence>
          {showTutorial ? <GetFileTutorial /> : <MinuteHistory />}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
}
