import React, { createContext, useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { getMeetingMinuteHistory } from "../server/getMeetingMinuteHistory";

const MeetingMinuteContext = createContext();

export const useMeetingMinute = () => useContext(MeetingMinuteContext);

export const MeetingMinuteProvider = ({ children }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [meetingData, setMeetingData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [meetingMinuteHistory, setMeetingMinuteHistory] = useState([]);
  const [isFetchingMinuteHistory, setIsFetchingMinuteHistory] = useState(true);
  const [currentMeetingMinuteId, setCurrentMeetingMinuteId] = useState("");
  const [takingTooLong, setTakingTooLong] = useState(false);
  const [fileTooLargeError, setFileTooLargeError] = useState(false);
  const [showMeetingMinutesScreen, setShowMeetingMinutesScreen] =
    useState(false);
  const [step, setStep] = useState(1);

  const processVTTFile = async (
    file,
    onSuccess,
    userEmail,
    userId,
    userName
  ) => {
    const reader = new FileReader();
    reader.onload = () => {
      const vttContent = reader.result;
      sendVTTToWebSocket(
        vttContent,
        onSuccess,
        userEmail,
        userId,
        userName,
        file.name.replace(/\.vtt$/i, "")
      );
    };
    reader.onerror = () => console.error("Error reading the VTT file");
    reader.readAsText(file);
  };

  const sendVTTToWebSocket = (
    vttContent,
    onSuccess,
    userEmail,
    userId,
    userName,
    filename
  ) => {
    const wsUrl = "wss://ata-extractor-rnp.hmg.apps.kloud.rnp.br/ws";
    const ws = new WebSocket(wsUrl);
    if (loading !== true) {
      setLoading(true);
    }

    let timeout;
    setTakingTooLong(false);
    setFileTooLargeError(false);

    ws.onopen = () => {
      let message = JSON.stringify({
        user_email: userEmail,
        user_id: userId,
        user_name: userName,
        vtt_content: vttContent,
        file_name: filename,
      });
      ws.send(message);

      timeout = setTimeout(() => {
        setTakingTooLong(true);
      }, 15000);

      const pingInterval = setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ ping: "ping" }));
        }
      }, 30000);

      ws.onclose = () => {
        clearInterval(pingInterval);
        setTakingTooLong(false);
        clearTimeout(timeout);
      };
    };

    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);

      if (response.error) {
        if (
          response.error.startsWith(
            "Erro ao processar a mensagem: Arquivo .vtt excede o limite de 110000 tokens"
          )
        ) {
          setFileTooLargeError(true);
          setMeetingData(null);
          setLoading(false);
          clearTimeout(timeout);
          setTakingTooLong(false);
          setStep(1);
          ws.close();
          return;
        }
        return;
      }
      clearTimeout(timeout);
      setLoading(false);
      setTakingTooLong(false);
      setMeetingData(response);
      setCurrentMeetingMinuteId(response.response.id);
      if (onSuccess) onSuccess();
      ws.close();
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      clearTimeout(timeout);
      setTakingTooLong(false);
    };
  };

  const openDialog = (props) => {
    setDialogProps(props);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setDialogProps({});
  };

  const fetchMeetingMinute = async (page = 1) => {
    try {
      setIsFetchingMinuteHistory(true);
      setLoading(true);
      const response = await getMeetingMinuteHistory(page);
      console.debug("Dados recebidos no fetchMeetingMinute:", response);

      // Atualize o estado
      if (page === 1) {
        setMeetingMinuteHistory(response);
      } else {
        setMeetingMinuteHistory((prev) => {
          const updatedHistory = [...prev, ...response];
          console.debug("Estado atualizado no contexto:", updatedHistory);
          setIsFetchingMinuteHistory(false);
          return updatedHistory;
        });
      }
    } catch (error) {
      console.error("Erro ao buscar atas de reunião:", error);
    } finally {
      setIsFetchingMinuteHistory(false);
      setLoading(false);
    }
  };

  const contextValue = useMemo(
    () => ({
      isDialogOpen,
      openDialog,
      closeDialog,
      dialogProps,
      meetingData,
      setMeetingData,
      processVTTFile,
      loading,
      setLoading,
      meetingMinuteHistory,
      setMeetingMinuteHistory,
      fetchMeetingMinute,
      isFetchingMinuteHistory,
      setIsFetchingMinuteHistory,
      currentMeetingMinuteId,
      setCurrentMeetingMinuteId,
      takingTooLong,
      setTakingTooLong,
      fileTooLargeError,
      setFileTooLargeError,
      showMeetingMinutesScreen,
      setShowMeetingMinutesScreen,
      step,
      setStep,
    }),
    [
      isDialogOpen,
      dialogProps,
      meetingData,
      loading,
      meetingMinuteHistory,
      setMeetingMinuteHistory,
      fetchMeetingMinute,
      isFetchingMinuteHistory,
      currentMeetingMinuteId,
      takingTooLong,
      fileTooLargeError,
      showMeetingMinutesScreen,
      step,
    ]
  );

  return (
    <MeetingMinuteContext.Provider value={contextValue}>
      {children}
    </MeetingMinuteContext.Provider>
  );
};

MeetingMinuteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
