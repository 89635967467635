import React from "react";
import { useChatContext } from "../../../contexts/ChatContext";
import {
  ChatBubbleLeftEllipsisIcon,
  ClipboardDocumentListIcon,
  DocumentIcon,
} from "@heroicons/react/24/outline";
import { useMeetingMinute } from "../../../contexts/MeetingMinuteContext";
import getUserInfo from "../../../server/user-auth/UserInfo";

/**
 * Componente de menu para selecionar uma nova conversa ou relatório.
 *
 * @component
 * @returns {JSX.Element} O menu de opções de chat ou relatório.
 */
export default function ModulesMenu() {
  const {
    setMessages,
    setCurrentChatProtocol,
    setCurrentChatHistoric,
    setCurrentChatKnowledge,
    setShowReportScreen,
    setResetConversation,
    setOnlyRead,
    setIsPoll,
    setMobileNavBarOpen,
    currentSelection,
    setCurrentSelection,
    setCurrentChatType,
  } = useChatContext();

  const { setShowMeetingMinutesScreen } = useMeetingMinute();

  const { setMeetingData } = useMeetingMinute();

  // Obtenção das informações do usuário
  const userData = getUserInfo();
  const userEmail = userData.email;

  // Recupera e processa a lista de emails permitidos da variável de ambiente
  const whitelistEnv = process.env.REACT_APP_MEETING_MINUTES_WHITELIST || "";
  const meetingMinutesWhitelist = whitelistEnv
    .split(",")
    .map((email) => email.trim().toLowerCase());

  // Verifica se o userEmail está na whitelist
  const isUserWhitelisted = meetingMinutesWhitelist.includes(
    userEmail.toLowerCase()
  );

  /**
   * Função para lidar com a seleção de uma conversa.
   *
   * @param {Object[]} selectedChat - O chat selecionado pelo usuário.
   */
  const handleConversationSelect = (selectedChat) => {
    if (Array.isArray(selectedChat) && selectedChat.length === 0) {
      setShowReportScreen(false);
      setShowMeetingMinutesScreen(false);
      setMessages([]);
      setCurrentChatProtocol({});
      setCurrentChatHistoric([]);
      setCurrentChatKnowledge({});
      setCurrentChatType("");
      setMeetingData(null);
    } else {
      setMessages(
        selectedChat.messages.map((message) => {
          return {
            id: message.id,
            role: message.role,
            question: message.role === "user" ? message.content : "",
            answer: message.role === "assistant" ? message.content : "",
            feedback: message.feedback,
          };
        })
      );
      setShowReportScreen(false);
      setCurrentChatHistoric(selectedChat.historic || []);
      setCurrentChatKnowledge(selectedChat.knowledge || {});
      setCurrentChatProtocol(selectedChat.protocol || {});
      setCurrentChatType(selectedChat.type || "");
      setMeetingData(null);
    }
  };

  return (
    <div>
      <div className="-mx-2">
        <div className="flex flex-col space-y-1 select-none">
          <div>
            <button
              className={`group w-full flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold transition-colors cursor-pointer duration-300 ${
                currentSelection === "Nova conversa"
                  ? "bg-slate-800 text-white"
                  : "text-slate-400 hover:text-white hover:bg-slate-800"
              }`}
              onClick={() => {
                handleConversationSelect([]);
                setShowMeetingMinutesScreen(false);
                setOnlyRead(false);
                setIsPoll(false);
                setCurrentSelection("Nova conversa");
                setMobileNavBarOpen(false);
                setMeetingData(null);
              }}
            >
              <ChatBubbleLeftEllipsisIcon
                className="size-6 shrink-0"
                aria-hidden="true"
              />
              Nova conversa
            </button>
          </div>

          <div>
            <button
              className={`group w-full flex gap-x-3 cursor-pointer rounded-md p-2 text-sm leading-6 font-semibold transition-colors duration-300 ${
                currentSelection === "Novo relatório"
                  ? "bg-slate-800 text-white"
                  : "text-slate-400 hover:text-white hover:bg-slate-800"
              }`}
              onClick={() => {
                setShowReportScreen(true);
                setResetConversation(true);
                setShowMeetingMinutesScreen(false);
                setCurrentChatProtocol({});
                setCurrentChatHistoric([]);
                setCurrentChatKnowledge({});
                setCurrentSelection("Novo relatório");
                setMobileNavBarOpen(false);
                setMeetingData(null);
              }}
            >
              <DocumentIcon className="size-6 shrink-0" aria-hidden="true" />
              Novo relatório
            </button>
          </div>

          <div>
            <button
              className={`group  w-full flex gap-x-3 cursor-pointer rounded-md p-2 text-sm leading-6 font-semibold transition-colors duration-300 ${
                currentSelection === "Atas de reunião"
                  ? "bg-slate-800 text-white"
                  : "text-slate-400 hover:text-white hover:bg-slate-800"
              }`}
              onClick={() => {
                setResetConversation(true);
                setShowReportScreen(false);
                setCurrentChatProtocol({});
                setCurrentChatHistoric([]);
                setCurrentChatKnowledge({});
                setCurrentSelection("Atas de reunião");
                setMobileNavBarOpen(false);
                setShowMeetingMinutesScreen(true);
                setMeetingData(null);
              }}
            >
              <ClipboardDocumentListIcon
                className="size-6 shrink-0"
                aria-hidden="true"
              />
              Reuniões
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
