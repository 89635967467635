import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  PhotoIcon,
  PaperClipIcon,
  DocumentIcon,
} from "@heroicons/react/24/solid";
import FileUploadError from "./FileUploadError/FileUploadError";
import { motion, AnimatePresence } from "framer-motion";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";

FileUpload.propTypes = {
  onFileSelect: PropTypes.func.isRequired,
  externalFiles: PropTypes.array.isRequired,
  label: PropTypes.string,
  validTypes: PropTypes.array.isRequired,
};

export default function FileUpload({
  onFileSelect,
  externalFiles,
  label,
  validTypes,
}) {
  const [draggedOver, setDraggedOver] = useState(false);
  const [files, setFiles] = useState([]);
  const [isFileSet, setIsFileSet] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const isValidFileType = (filename) => {
    return validTypes.some((type) =>
      filename.toLowerCase().endsWith(`.${type}`)
    );
  };

  useEffect(() => {
    setFiles(externalFiles);
  }, [externalFiles]);

  const handleFiles = (e, filesInput) => {
    const fileList = filesInput;
    const selectedFiles = Array.from(fileList);
    const newValidFiles = selectedFiles.filter((file) =>
      isValidFileType(file.name)
    );
    if (newValidFiles.length > 0) {
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, ...newValidFiles];
        onFileSelect(updatedFiles);
        setIsFileSet(true);
        setErrorMessage(false);
        // newValidFiles.forEach((file) => {
        //   if (file.name.toLowerCase().endsWith(".vtt")) {
        //     readAndSendVTTFile(file);
        //   }
        // });
        return updatedFiles;
      });
    } else if (selectedFiles.length > 0) {
      // Lógica para arquivos inválidos, se necessário
      setErrorMessage(true);
    }

    document.getElementById("file-upload").value = "";
  };

  // const readAndSendVTTFile = (file) => {
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     const vttContent = reader.result;
  //     sendVTTContent(vttContent);
  //   };
  //   reader.onerror = () => {
  //     console.error("Error reading the VTT file");
  //   };
  //   reader.readAsText(file);
  // };

  // const sendVTTContent = (vttContent) => {
  //   const wsUrl = "wss://ata-extractor-rnp.hmg.apps.kloud.rnp.br/ws";
  //   const ws = new WebSocket(wsUrl);

  //   ws.onopen = () => {
  //     const message = JSON.stringify({ vtt_content: vttContent });
  //     ws.send(message);
  //   };

  //   ws.onmessage = (event) => {
  //     console.log("Resposta do servidor:", event.data);
  //   };

  //   ws.onerror = (error) => {
  //     console.error("WebSocket error:", error);
  //   };

  //   ws.onclose = () => {
  //     console.log("WebSocket connection closed");
  //   };
  // };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDraggedOver(true);
  };

  const handleDragLeave = (e) => {
    setDraggedOver(false);
    e.preventDefault();
  };

  const handleDrop = (e) => {
    setDraggedOver(false);
    e.preventDefault();
    handleFiles(e, e.dataTransfer.files);
  };

  const handleFileRemove = (index) => {
    const updatedFiles = files.filter((_, idx) => idx !== index);
    setFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      setIsFileSet(false);
      document.getElementById("file-upload").value = "";
    }
  };

  const getFileIcon = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
      case "docx":
        return (
          <DocumentIcon
            className="flex-shrink-0 text-slate-400 size-5"
            aria-hidden="true"
          />
        );
      case "jpg":
      case "jpeg":
      case "png":
        return (
          <PhotoIcon
            className="flex-shrink-0 text-slate-400 size-5"
            aria-hidden="true"
          />
        );
      default:
        return (
          <PaperClipIcon
            className="flex-shrink-0 text-slate-400 size-5"
            aria-hidden="true"
          />
        );
    }
  };

  const getValidTypesText = () => {
    if (validTypes.length > 1)
      return (
        validTypes
          .map((type) => type.toUpperCase())
          .slice(0, -1)
          .join(", ") +
        " ou " +
        validTypes[validTypes.length - 1].toUpperCase()
      );
    return `São aceitos apenas arquivos em ${validTypes[0].toUpperCase()}`;
  };

  return (
    <div className="pb-5 col-span-full">
      {errorMessage && (
        <FileUploadError
          onclose={() => {
            setTimeout(() => {
              setErrorMessage(false);
            }, 300);
          }}
          validTypes={getValidTypesText()}
        />
      )}
      <label
        htmlFor="cover-photo"
        className="block text-sm font-medium leading-6 select-none text-slate-900 dark:text-slate-100"
      >
        {label}
      </label>
      <div
        className="flex justify-center w-full px-6 py-10 mt-2 border border-dashed rounded-lg cursor-pointer border-slate-900/25 dark:border-slate-100/25 dark:bg-slate-900/60 bg-slate-50/40 backdrop-blur-5xl backdrop-filter"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => document.getElementById("file-upload").click()}
      >
        {draggedOver ? (
          <div className="text-center">
            {Array.isArray(validTypes) && validTypes.includes("vtt") ? (
              <ClipboardDocumentListIcon
                className="mx-auto text-slate-300 size-12 dark:text-slate-700"
                aria-hidden="true"
              />
            ) : (
              <PhotoIcon
                className="mx-auto text-slate-300 size-12 dark:text-slate-700"
                aria-hidden="true"
              />
            )}
            <div className="flex mt-4 text-sm leading-6 text-slate-600 dark:text-slate-400">
              <p className="mx-auto">Solte o arquivo aqui</p>
            </div>
            <p className="text-xs leading-5 text-slate-600 dark:text-slate-400">
              {getValidTypesText()}
            </p>
          </div>
        ) : (
          <div className="text-center">
            {Array.isArray(validTypes) && validTypes.includes("vtt") ? (
              <ClipboardDocumentListIcon
                className="mx-auto text-slate-300 size-12 dark:text-slate-700"
                aria-hidden="true"
              />
            ) : (
              <PhotoIcon
                className="mx-auto text-slate-300 size-12 dark:text-slate-700"
                aria-hidden="true"
              />
            )}
            <div className="flex mt-4 text-sm leading-6 text-slate-600 dark:text-slate-400">
              <button
                type="button"
                className="relative font-semibold text-indigo-600 transition-colors duration-300 rounded-md cursor-pointer bg-defaultWhite dark:bg-slate-900 dark:text-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 hover:text-indigo-500 hover:dark:text-indigo-300"
                onClick={(e) => {
                  e.stopPropagation();
                  document.getElementById("file-upload").click();
                }}
              >
                <span className="px-1">Enviar um arquivo</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  onChange={(e) => {
                    handleFiles(e, e.target.files);
                    setIsFileSet(true);
                  }}
                  onDrop={(e) => handleFiles(e, e.dataTransfer.files)}
                  multiple
                />
              </button>
              <p className="pl-1">ou arraste e solte</p>
            </div>
            <p className="text-xs leading-5 text-slate-600 dark:text-slate-400">
              {getValidTypesText()}
            </p>
          </div>
        )}
      </div>
      {isFileSet &&
        files
          .filter((file) => !file.name.toLowerCase().endsWith(".vtt")) // Filter out .vtt files
          .map((file, index) => (
            <AnimatePresence key={file.name}>
              <motion.div
                key={file.name} // Ensure key is unique
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                unmount={true}
              >
                <li className="flex items-center justify-between pt-4 pl-4 pr-5 text-sm leading-6">
                  <div className="flex items-center flex-1 w-0">
                    {getFileIcon(file.name)}
                    <div className="flex flex-1 min-w-0 gap-2 ml-4">
                      <span className="font-medium truncate">{file.name}</span>
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 ml-4 space-x-4">
                    <button
                      type="button"
                      className="font-medium text-indigo-600 transition-colors duration-300 rounded-md dark:text-indigo-400 hover:text-indigo-500 hover:dark:text-indigo-300"
                      onClick={() => handleFileRemove(index)}
                    >
                      Remover
                    </button>
                  </div>
                </li>
              </motion.div>
            </AnimatePresence>
          ))}
    </div>
  );
}
