import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { DataTable } from "../../../Table/DataTable";
import initialData from "../../../../config/meetingMinuteMock.json";
import {
  DocumentTextIcon,
  ClockIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import { useMeetingMinute } from "../../../../contexts/MeetingMinuteContext";
import generateMeetingMinute from "../../../../server/generateMeetingMinute";
import getPercentageValue from "../../../../utils/stringFormatter";
import getUserInfo from "../../../../server/user-auth/UserInfo";
import SkeletonRows from "../../../SkeletonRows/SkeletonRows";
import { MeetingMinuteLoading } from "./MeetingMinuteLoading/MeetingMinuteLoading";
import { AnimatePresence } from "framer-motion";
import Wordcloud from "./Wordcloud/Wordcloud";
import updateMeetingMinute from "../../../../server/updateMeetingMinute";
import { LoadingAnimation } from "../../../LoadingAnimation/LoadingAnimation";
// import { InformationTooltip } from "../../../InformationTooltip/InformationTooltip";
import ChatMessages from "../../ChatMessages/ChatMessages";
import { parseMessages } from "../../../../utils/parseMessages";

export default function MeetingMinuteDetails() {
  const { handleSubmit } = useForm();
  const {
    openDialog,
    meetingData,
    loading,
    setLoading,
    currentMeetingMinuteId,
  } = useMeetingMinute();
  const [formData, setFormData] = useState(initialData);
  const [updatingFormData, setUpdatingFormData] = useState(false);
  const [generatingMeetingMinute, setGeneratingMeetingMinute] = useState(false);

  const userInfo = getUserInfo();

  const username = userInfo.fullName
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  useEffect(() => {
    if (meetingData) {
      setFormData(meetingData);
      setFormData((prev) => ({
        ...prev,
        dados_gerais: {
          ...prev.dados_gerais,
          registrado_por: username,
        },
      }));
    }
  }, [meetingData]);

  useEffect(() => {
    if (formData.dados_gerais.local === "Teams") {
      setLoading(false);
      const timeout = setTimeout(() => {
        setUpdatingFormData(true);
        updateMeetingMinute(currentMeetingMinuteId, formData)
          .then(() => {
            setUpdatingFormData(false);
          })
          .catch((error) => {
            console.debug("Erro ao atualizar a ATA de reunião:", error);
            setUpdatingFormData(false);
          });
      }, 3000);

      return () => clearTimeout(timeout);
    } else {
      setLoading(true);
    }
  }, [formData]);

  const handleEditClick = (section, rowData, fields, rowIndex) => {
    openDialog({
      title: `Editar item`,
      introText: "Revise os campos a seguir para editar as informações.",
      icon: <DocumentTextIcon className="text-indigo-700 size-6" />,
      fields,
      initialValues: rowData,
      onSubmit: (updatedData) =>
        handleDialogSubmit(section, updatedData, rowIndex),
      actionButtons: [
        {
          label: "Remover",
          isCancel: true,
          onClick: () => handleRemoveItem(section, rowIndex),
          className:
            "!bg-red-600 text-white hover:!bg-red-500 smooth-colors ring-red-600 dark:ring-red-600",
        },
        {
          label: "Salvar",
          type: "submit",
          className: "bg-indigo-600 text-white hover:bg-indigo-500",
        },
      ],
    });
  };

  const handleRemoveItem = (section, rowIndex) => {
    setFormData((prevFormData) => {
      const updatedSection = prevFormData[section] || [];

      const newSection = [
        ...updatedSection.slice(0, rowIndex),
        ...updatedSection.slice(rowIndex + 1),
      ];

      return {
        ...prevFormData,
        [section]: newSection,
      };
    });
  };

  const handleAddClick = (section, fields) => {
    openDialog({
      title: `Adicionar item`,
      introText:
        "Preencha as informações a seguir para adicionar um novo item.",
      icon: <DocumentTextIcon className="text-indigo-700 size-6" />,
      fields,
      initialValues: {},
      onSubmit: (newData) => handleDialogSubmit(section, newData),
      actionButtons: [
        {
          label: "Cancelar",
          isCancel: true,
          onClick: () => console.debug("Cancelar adição"),
          className:
            "bg-slate-50 text-slate-700 hover:bg-slate-100 dark:bg-[#1d2432] dark:text-slate-50 dark:ring-slate-700 dark:hover:bg-slate-700 smooth-colors",
        },
        {
          label: "Adicionar",
          type: "submit",
          className: "bg-indigo-600 text-white hover:bg-indigo-500",
        },
      ],
    });
  };

  const handleDialogSubmit = (section, updatedData, rowIndex = null) => {
    setFormData((prevFormData) => {
      const updatedSection = prevFormData[section] || [];
      let newSection;

      if (rowIndex !== null && rowIndex >= 0) {
        newSection = [
          ...updatedSection.slice(0, rowIndex),
          updatedData,
          ...updatedSection.slice(rowIndex + 1),
        ];
      } else {
        newSection = [...updatedSection, updatedData];
      }

      return {
        ...prevFormData,
        [section]: newSection,
      };
    });
  };

  const handleFormSubmit = () => {
    setGeneratingMeetingMinute(true);
    generateMeetingMinute(formData, formData.file_name)
      .then(() => {
        setGeneratingMeetingMinute(false);
      })
      .catch((error) => {
        console.debug("Erro ao gerar a ATA de reunião:", error);
        setGeneratingMeetingMinute(false);
      });
  };

  return (
    <>
      <div
        className="max-w-[800px] sm:min-w-[800px] mx-auto my-6 mt-[65px] pb-20 text-slate-700 dark:text-slate-50"
        id="meetingMinutesDetailsContainer"
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="flex mb-5 sm:col-span-6">
            <h2 className="-mb-2 text-lg font-bold">Dados da reunião</h2>
            <div className="flex my-auto ml-auto ">
              <AnimatePresence>
                {generatingMeetingMinute && (
                  <div className="h-full my-auto mr-3">
                    <LoadingAnimation />
                  </div>
                )}
              </AnimatePresence>
              <button
                className="btn-primary"
                disabled={loading | generatingMeetingMinute}
                type="submit"
              >
                Gerar relatório
              </button>
            </div>
          </div>
          {/* Dados Gerais */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <h2 className="-mb-2 text-base font-bold">Dados Gerais</h2>
            </div>
            <div className="sm:col-span-6 default-input-ring">
              <label htmlFor="nome" className="default-input-label">
                Título
              </label>
              {loading ? (
                <SkeletonRows rows={1} />
              ) : (
                <input
                  id="nome"
                  type="text"
                  placeholder="Digite uma breve descrição da reunião"
                  className="text-sm default-input"
                  value={formData.titulo}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      titulo: e.target.value,
                    }))
                  }
                />
              )}
            </div>
            <div className="sm:col-span-6">
              <div className="default-input-ring">
                <label htmlFor="assunto" className="default-input-label">
                  Assunto
                </label>
                {loading ? (
                  <SkeletonRows rows={8} />
                ) : (
                  <textarea
                    id="assunto"
                    placeholder="Digite o assunto"
                    className="text-sm default-input"
                    value={formData.dados_gerais.assunto}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        dados_gerais: {
                          ...prev.dados_gerais,
                          assunto: e.target.value,
                        },
                      }))
                    }
                    rows={formData.dados_gerais.assunto.length / 70}
                  />
                )}
              </div>
            </div>
            {/* Linha: Hora de Início, Hora de Término e Data */}
            <div className="sm:col-span-2 default-input-ring">
              <label htmlFor="hora_inicio" className="default-input-label">
                Hora de Início
              </label>
              {loading ? (
                <SkeletonRows rows={1} />
              ) : (
                <input
                  id="hora_inicio"
                  type="time"
                  placeholder="Digite a hora de início"
                  className="text-sm default-input"
                  value={formData.dados_gerais.hora_inicio}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      dados_gerais: {
                        ...prev.dados_gerais,
                        hora_inicio: e.target.value,
                      },
                    }))
                  }
                />
              )}
              <ClockIcon className="absolute bg-white pointer-events-none text-slate-500 dark:text-slate-300 bottom-2 size-5 right-3 dark:bg-slate-900" />
            </div>

            <div className="sm:col-span-2 default-input-ring">
              <label htmlFor="hora_termino" className="default-input-label">
                Hora de Término
              </label>
              {loading ? (
                <SkeletonRows rows={1} />
              ) : (
                <input
                  id="hora_termino"
                  type="time"
                  placeholder="Digite a hora de término"
                  className="text-sm default-input"
                  value={formData.dados_gerais.hora_termino}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      dados_gerais: {
                        ...prev.dados_gerais,
                        hora_termino: e.target.value,
                      },
                    }))
                  }
                />
              )}
              <ClockIcon className="absolute bg-white pointer-events-none text-slate-500 dark:text-slate-300 bottom-2 size-5 right-3 dark:bg-slate-900" />
            </div>

            <div className="sm:col-span-2 default-input-ring">
              <label htmlFor="data" className="default-input-label">
                Data
              </label>
              {loading ? (
                <SkeletonRows rows={1} />
              ) : (
                <input
                  id="data"
                  type="date"
                  placeholder="Digite a data"
                  className="text-sm default-input"
                  value={formData.dados_gerais.data}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      dados_gerais: {
                        ...prev.dados_gerais,
                        data: e.target.value,
                      },
                    }))
                  }
                />
              )}
              <CalendarIcon className="absolute bg-white pointer-events-none text-slate-500 dark:text-slate-300 bottom-2 size-5 right-3 dark:bg-slate-900" />
            </div>

            {/* Linha: Local e Registrado Por */}
            <div className="sm:col-span-3 default-input-ring">
              <label htmlFor="local" className="default-input-label">
                Local
              </label>
              {loading ? (
                <SkeletonRows rows={1} />
              ) : (
                <input
                  id="local"
                  type="text"
                  placeholder="Digite o local"
                  className="default-input"
                  value={formData.dados_gerais.local}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      dados_gerais: {
                        ...prev.dados_gerais,
                        local: e.target.value,
                      },
                    }))
                  }
                  disabled
                />
              )}
            </div>

            <div className="sm:col-span-3 default-input-ring">
              <label htmlFor="registrado_por" className="default-input-label">
                Registrado por
              </label>
              {loading ? (
                <SkeletonRows rows={1} />
              ) : (
                <input
                  id="registrado_por"
                  type="text"
                  placeholder="Digite o nome do registrador"
                  className="default-input"
                  value={formData.dados_gerais.registrado_por}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      dados_gerais: {
                        ...prev.dados_gerais,
                        registrado_por: e.target.value,
                      },
                    }))
                  }
                />
              )}
            </div>
          </div>

          {/* Participantes */}
          <div className="mt-8">
            <h2 className="-mb-5 text-base font-bold">Participantes</h2>
            <DataTable
              columns={[
                { title: "Nome", field: "nome", variant: "main" },
                { title: "Email", field: "email", variant: "hidden" },
                {
                  title: "Organização",
                  field: "organizacao",
                  variant: "hidden",
                },
                { title: "Atuação", field: "atuacao", variant: "hidden" },
              ]}
              data={formData.participantes}
              actions={[
                {
                  label: "Editar",
                  onClick: (rowData, rowIndex) =>
                    handleEditClick(
                      "participantes",
                      rowData,
                      [
                        {
                          name: "nome",
                          label: "Nome",
                          value: rowData.nome,
                          required: true,
                        },
                        {
                          name: "email",
                          label: "Email",
                          value: rowData.email,
                        },
                        {
                          name: "organizacao",
                          label: "Organização",
                          value: rowData.organizacao,
                        },
                        {
                          name: "atuacao",
                          label: "Atuação",
                          value: rowData.atuacao,
                        },
                      ],
                      rowIndex
                    ),
                },
              ]}
              onClickAdd={() =>
                handleAddClick("participantes", [
                  { name: "nome", label: "Nome", value: "", required: true },
                  { name: "email", label: "Email", value: "" },
                  { name: "organizacao", label: "Organização", value: "" },
                  { name: "atuacao", label: "Atuação", value: "" },
                ])
              }
              searchable
              addable
              pagination={{
                rowsPerPageOptions: [10, 20, 30],
                defaultRowsPerPage: 10,
              }}
              isLoading={loading}
            />
          </div>

          {/* Pendências */}
          <div className="mt-8">
            <h2 className="mb-5 text-base font-bold">Pendências</h2>
            <div className="space-y-4">
              <div className="sm:col-span-2 default-input-ring">
                <label htmlFor="data" className="default-input-label">
                  Data da última reunião
                </label>
                {loading ? (
                  <SkeletonRows rows={1} />
                ) : (
                  <input
                    id="data_reuniao_anterior"
                    type="date"
                    placeholder="Digite a data"
                    className="text-sm default-input"
                    value={formData.pendencias.data_reuniao_anterior}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        pendencias: {
                          ...prev.pendencias,
                          data_reuniao_anterior: e.target.value,
                        },
                      }))
                    }
                  />
                )}
                <CalendarIcon className="absolute bg-white pointer-events-none text-slate-500 dark:text-slate-300 bottom-2 size-5 right-3 dark:bg-slate-900" />
              </div>
              {["atividades_realizadas", "atividades_pendentes"].map(
                (field) => (
                  <div key={field} className="default-input-ring">
                    <label htmlFor={field} className="default-input-label">
                      {field
                        .replace("_", " ")
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                    </label>
                    {loading ? (
                      <SkeletonRows rows={5} />
                    ) : (
                      <textarea
                        id={field}
                        placeholder={`Digite ${field.replace("_", " ")}`}
                        className="default-input"
                        value={formData.pendencias[field]}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            pendencias: {
                              ...prev.pendencias,
                              [field]: e.target.value,
                            },
                          }))
                        }
                        rows={field.length < 400 ? 4 : field.length / 70}
                      />
                    )}
                  </div>
                )
              )}
            </div>
          </div>

          {/* Pauta */}
          <div className="mt-8">
            <h2 className="-mb-5 text-base font-bold">Pauta</h2>
            <DataTable
              columns={[
                { title: "Item", field: "item", variant: "main" },
                {
                  title: "Detalhamento",
                  field: "detalhamento",
                  variant: "hidden",
                },
              ]}
              data={formData.pauta}
              actions={[
                {
                  label: "Editar",
                  onClick: (rowData, rowIndex) =>
                    handleEditClick(
                      "pauta",
                      rowData,
                      [
                        {
                          name: "item",
                          label: "Item",
                          value: rowData.item,
                          required: true,
                        },
                        {
                          name: "detalhamento",
                          label: "Detalhamento",
                          value: rowData.detalhamento,
                        },
                      ],
                      rowIndex
                    ),
                },
              ]}
              onClickAdd={() =>
                handleAddClick("pauta", [
                  { name: "item", label: "Item", value: "", required: true },
                  {
                    name: "detalhamento",
                    label: "Detalhamento",
                    value: "",
                  },
                ])
              }
              searchable
              addable
              pagination={{
                rowsPerPageOptions: [10, 20, 30],
                defaultRowsPerPage: 10,
              }}
              isLoading={loading}
            />
          </div>

          {/* Propostas de Ação */}
          <div className="mt-8">
            <h2 className="-mb-5 text-base font-bold">Propostas de Ação</h2>
            <DataTable
              columns={[
                { title: "Descrição", field: "descricao", variant: "main" },
              ]}
              data={formData.propostas_acao}
              actions={[
                {
                  label: "Editar",
                  onClick: (rowData, rowIndex) =>
                    handleEditClick(
                      "propostas_acao",
                      rowData,
                      [
                        {
                          name: "descricao",
                          label: "Descrição",
                          value: rowData.descricao,
                          required: true,
                        },
                      ],
                      rowIndex
                    ),
                },
              ]}
              onClickAdd={() =>
                handleAddClick("propostas_acao", [
                  {
                    name: "descricao",
                    label: "Descrição",
                    value: "",
                    required: true,
                  },
                ])
              }
              searchable
              addable
              pagination={{
                rowsPerPageOptions: [10, 20, 30],
                defaultRowsPerPage: 10,
              }}
              isLoading={loading}
            />
          </div>

          {/* Aprovação */}
          <div className="mt-8">
            <h2 className="-mb-5 text-base font-bold">Aprovação</h2>
            <DataTable
              columns={[{ title: "Nome", field: "nome", variant: "main" }]}
              data={formData.aprovacao}
              actions={[
                {
                  label: "Editar",
                  onClick: (rowData, rowIndex) =>
                    handleEditClick(
                      "aprovacao",
                      rowData,
                      [{ name: "nome", label: "Nome", value: rowData.nome }],
                      rowIndex
                    ),
                },
              ]}
              onClickAdd={() =>
                handleAddClick("aprovacao", [
                  { name: "nome", label: "Nome", value: "" },
                ])
              }
              searchable
              addable
              pagination={{
                rowsPerPageOptions: [10, 20, 30],
                defaultRowsPerPage: 10,
              }}
              isLoading={loading}
            />
          </div>

          {/* Divisor */}
          <div className="relative mt-5">
            <div
              aria-hidden="true"
              className="absolute inset-0 flex items-center"
            >
              <div className="w-full border-t border-slate-300 dark:border-slate-700" />
            </div>
          </div>

          <div className="mt-10 sm:col-span-6">
            <h2 className="text-lg font-bold ">Insights</h2>
          </div>
          <div className="mt-5">
            <h2 className="text-base font-bold">
              Sentimento da reunião:{" "}
              {loading ? (
                <SkeletonRows rows={1} />
              ) : (
                formData.sentimento.sentimento
              )}
            </h2>
            <div className="mt-2 text-sm text-slate-600 dark:text-slate-300">
              {loading ? (
                <SkeletonRows rows={10} />
              ) : (
                formData.sentimento.justificativa
              )}
            </div>
          </div>

          {/* Porcentagem de Fala */}
          <div className="mt-8">
            <h2 className="-mb-5 text-base font-bold">Porcentagem de Fala</h2>
            <DataTable
              columns={[
                { title: "Nome", field: "nome", variant: "main" },
                {
                  title: "Percentual de fala",
                  field: "percentual_fala",
                  variant: "main",
                },
              ]}
              data={getPercentageValue(formData.porcentagem_fala)}
              onClickAdd={() =>
                handleAddClick("porcentagem de fala", [
                  { name: "nome", label: "Nome", value: "" },
                  {
                    name: "percentual_fala",
                    label: "Percentual de Fala",
                    value: "",
                  },
                ])
              }
              searchable
              pagination={{
                rowsPerPageOptions: [10, 20, 30],
                defaultRowsPerPage: 10,
              }}
              isLoading={loading}
            />
          </div>

          {/* Perguntas chaves */}
          <div className="mt-8">
            <h2 className="-mb-5 text-base font-bold">Perguntas chaves</h2>
            <DataTable
              columns={[
                { title: "Pergunta", field: "pergunta", variant: "main" },
              ]}
              data={formData.perguntas_chave.perguntas_chave}
              searchable
              pagination={{
                rowsPerPageOptions: [10, 20, 30],
                defaultRowsPerPage: 10,
              }}
              isLoading={loading}
            />
          </div>

          {/* Nuvem de palavras */}
          <div className="mt-8">
            <h2 className="-mb-5 text-base font-bold ">Palavras chaves</h2>
            <Wordcloud data={formData.wordcloud} loading={loading} />
          </div>

          {/* Transcrição da reunião */}
          <div className="mt-8">
            <h2 className="-mb-5 text-base font-bold ">
              Transcrição da reunião
            </h2>
            {formData.messages ? (
              <div className="mt-10 bg-white rounded-lg dark:bg-slate-900 ring-1 ring-slate-300 dark:ring-slate-700 sm:mx-0 text-slate-700 dark:text-slate-200 h-[800px] overflow-y-auto overflow-x-hidden p-4">
                <div className="-mt-[65px]">
                  <ChatMessages
                    messages={formData.messages ? parseMessages(formData) : []}
                    lazyLoading={true}
                  />
                </div>
              </div>
            ) : (
              <div className="mt-10 bg-white rounded-lg dark:bg-slate-900 ring-1 ring-slate-300 dark:ring-slate-700 sm:mx-0 text-slate-700 dark:text-slate-200 h-[300px] overflow-y-auto overflow-x-hidden p-4 flex items-center justify-center">
                <div className="text-sm opacity-50 select-none">
                  As transcrições só estão disponíveis em relatórios mais
                  recentes.
                </div>
              </div>
            )}
          </div>
          <div className="flex pb-10 mt-5 sm:col-span-6">
            <div className="flex my-auto ml-auto ">
              <AnimatePresence>
                {generatingMeetingMinute && (
                  <div className="h-full my-auto mr-3">
                    <LoadingAnimation />
                  </div>
                )}
              </AnimatePresence>
              <button
                className="btn-primary"
                disabled={loading | generatingMeetingMinute}
                type="submit"
              >
                Gerar relatório
              </button>
            </div>
          </div>
        </form>
      </div>
      <AnimatePresence>
        {loading | updatingFormData && (
          <MeetingMinuteLoading
            bodyText={
              updatingFormData ? "Atualizando dados..." : "Inferindo dados..."
            }
          />
        )}
      </AnimatePresence>
    </>
  );
}
